






































































import {Component, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import PaginationMixin from '~/mixins/pagination-mixin'

type Rec = {
    _id: string,
    paths: string[],
    location: string,
    ua: string[],
    blocked?: boolean
}

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Sp Ip Log'
        }
    }
})
export default class SpIp extends mixins(PaginationMixin) {
    static permission = AdminPermission.IP

    lOptions: string[] = []
    selectedL: string[] = []

    orgData: Rec[] = []
    data: Rec[] = []

    start: Date | null = null
    end: Date | null = null
    keyword: string = ''

    @Watch('selectedL', {deep: true})
    dataUpdated() {
        this.data = (this.orgData || []).filter(row => {
            return this.selectedL.includes(row.location)
        })
    }
    async created() {
        await this.updateList()
    }
    async updateList(page = 1) {
        this.currentPage = page
        const q = {
            skip: (this.currentPage - 1) * this.step,
            limit: this.step
        }
        if (this.keyword)
            q['keyword'] = this.keyword
        if (this.start)
            q['start'] = this.start.getTime()
        if (this.end) {
            const d = new Date(this.end)
            d.setDate(d.getDate() + 1)
            q['end'] = d.getTime()
        }

        const res = await createRequest('/sp-ips', 'get', q).send()
        this.totalCount = res.data.count
        this.orgData = res.data.sp_ips
        const d = {}
        for (const row of this.orgData) {
            d[row.location] = true
        }

        this.lOptions = Object.keys(d)
        this.selectedL = Object.keys(d)
        this.dataUpdated()
    }
    async block(row: Rec) {
        await createRequest('/ip/block', 'post', {}, {ip: row._id}).send()
        this.$set(row, 'blocked', true)
    }
    async unblock(row: Rec) {
        await createRequest('/ip/unblock', 'post', {}, {ip: row._id}).send()
        this.$set(row, 'blocked', undefined)
    }
}

